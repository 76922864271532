<template>
  <div class="view">
    <AdminPanel :title="title">
      <el-form label-position="top">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item v-if="isAdminRole" label="카테고리(비밀코드 선택)" required>
              <el-select
                v-model="contest.secret_code"
                placeholder="강의에 맞는 비밀코드 선택"
              >
                <el-option
                  v-for="item in secret_code_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <!-- <el-input id="title" v-model="contest.title" placeholder="비밀코드를 선택해주세요"></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="제목" required for="title">
              <el-input
                id="title"
                v-model="contest.title"
                placeholder="제목"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="설명" required>
              <VueCkEditor
                :data="contest.description"
                @setContent="setContetDescription"
              ></VueCkEditor>

            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item
              :label="'시작 시간'"
              class="font-weight"
              required
              for="startTime"
            >
              <el-date-picker
                id="startTime"
                v-model="contest.start_time"
                type="date"
                format="yy년 M월 d일 H시 m분"
                placeholder="시작시간"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item
              label="종료 시간 (실수 조심!!)"
              class="font-weight"
              required
              for="endTime"
            >
              <el-date-picker
                id="endTime"
                v-model="contest.end_time"
                type="date"
                format="yy년 M월 d일 H시 m분"
                placeholder="종료 시간"
                
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="비밀번호" class="font-weight" for="password">
              <el-input
                id="password"
                v-model="contest.password"
                :placeholder="'비밀번호'"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item :label="'실시간 랭킹'" for="rank">
              <el-switch
                id="rank"
                v-model="contest.real_time_rank"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="집합 교육 공개 여부" for="visible">
              <el-switch
                id="visible"
                v-model="contest.visible"
                active-text=""
                inactive-text=""
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8">
            <el-form-item label="강사님 코드 공개여부" for="tutorCode">
              <el-switch
                id="tutorCode"
                v-model="contest.teacher_code_visible"
                active-text=""
                inactive-text=""
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!-- <el-form-item :label="'허용된 IP 범위'" for="ip">
              <div
                v-for="(range, index) in contest.allowed_ip_ranges"
                :key="index"
              >
                <el-row :gutter="20" style="margin-bottom: 15px">
                  <el-col :xs="24" :sm="24" :md="8" :lg="8">
                    <el-input
                      id="ip"
                      v-model="range.value"
                      placeholder="CIDR 네트워크"
                    ></el-input>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="8" :lg="8">
                    <el-button
                      plain
                      icon="el-icon-plus"
                      @click="addIPRange"
                    ></el-button>
                    <el-button
                      plain
                      icon="el-icon-delete"
                      @click="removeIPRange(range)"
                    ></el-button>
                  </el-col>
                </el-row>
              </div>
            </el-form-item> -->
          </el-col>
        </el-row>
      </el-form>
      <save @click.native="saveContest"></save>
    </AdminPanel>
  </div>
</template>

<script>

import api from "../../api.js";
import Simditor from "../../components/Simditor.vue";
import AdminPanel from "../../components/AdminPanel.vue";
import VueCkEditor from "../../components/VueCkEditor.vue";
import { mapGetters } from "vuex";
export default {
  name: "CreateContest",
  mixins: [],
  components: {
    Simditor,
    AdminPanel,
    VueCkEditor,
  },
  data() {
    return {
      title: "강의 생성하기",
      disableRuleType: false,
      value: "",
      secret_code_list: [],
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
        {
          value: "Option3",
          label: "Option3",
        },
        {
          value: "Option4",
          label: "Option4",
        },
        {
          value: "Option5",
          label: "Option5",
        },
      ],
      contest: {
        title: "",
        description: "",
        start_time: "",
        end_time: "",
        rule_type: "OI",
        password: "",
        teacher_code_visible: false,
        real_time_rank: true,
        visible: true,
        allowed_ip_ranges: [
          {
            value: "",
          },
        ],
        secret_code: "",
      },
    };
  },
  methods: {
    saveContest() {
      let funcName =
        this.$route.name === "edit-contest" ? "editContest" : "createContest";

      let data = Object.assign({}, this.contest);
      let ranges = [];
      for (let v of data.allowed_ip_ranges) {
        if (v.value !== "") {
          ranges.push(v.value);
        }
      }
      data.allowed_ip_ranges = ranges;
      api[funcName](data)
        .then((res) => {
          this.$router.push({
            name: "contest-list",
            query: { refresh: "true", page: this.$route.query.page },
          });
        })
        .catch(() => {});
    },
    addIPRange() {
      this.contest.allowed_ip_ranges.push({ value: "" });
    },
    removeIPRange(range) {
      let index = this.contest.allowed_ip_ranges.indexOf(range);
      if (index !== -1) {
        this.contest.allowed_ip_ranges.splice(index, 1);
      }
    },
    changeEndTime() {
      this.contest.end_time.setMinutes(this.contest.end_time.getMinutes() + 1);
    },
    setContetDescription(e) {
      this.contest.description = e;
    },
  },
  computed: {
        ...mapGetters(["isSuperAdmin", "isAdminRole", "isManager"]),
        
    },
  async mounted() {
    // edit과 상관없이 일반 secret_code list 가져오기
    const { data } = await api.getSecretCodeAuth();
    this.secret_code_list = data.data;

    if (this.$route.name === "edit-contest") {
      this.title = "강의 수정하기";
      this.disableRuleType = true;
      api
        .getContest(this.$route.params.contestId)
        .then((res) => {
          let data = res.data.data;
          let ranges = [];
          for (let v of data.allowed_ip_ranges) {
            ranges.push({ value: v });
          }
          if (ranges.length === 0) {
            ranges.push({ value: "" });
          }
          data.allowed_ip_ranges = ranges;
          this.contest = data;
        })
        .catch(() => {});
    }
  },
};
</script>

<style lang="less">
.font-weight > .el-form-item__label {
  font-weight: 600;
}
</style>
